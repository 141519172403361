.stepperSubDiv {
  margin: 1.4em;
  background: linear-gradient(to right, rgb(204, 157, 242), rgb(145, 128, 255), rgb(255, 255, 255));
  border-radius: 12px;
  padding: 1.4em;
  color: #0d0d0d;
}

.stepperSubDiv h1 {
  font-weight: 500;
  text-align: center;
  color: #000201;
}

.stepperBtn {
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  background: #935cc4;
  background: #b866ff;
  color: #f7f7f7;
  border-radius: 12px;
  padding: 0.5em 1.4em;
  font-size: 1.2em;
  font-weight: 500;
  margin: 1.4em;
}