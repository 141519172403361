
.highlightRow td {
 
  font-weight: bold;
  color: black; 
}

.right-align-btn {
  margin-left: 50px;
}


.btn-gradient {
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  color: white; 
}


.remove-padding {
  padding-left: 2px;
  padding-right: 2px;
}