.tbody tr:hover {
  background-color: #e71c1c;
  transition: background-color 0.3s ease;
}

.remove-padding {
  padding-left: 2px;
  padding-right: 2px;
}

.branch-id {
  font-weight: bold;
  font-size: 1.2em;
}

.table-header {
  background: #c6c3e8;
  color: white;
}
