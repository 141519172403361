.stepperSubDiv {
  margin: 1.4em;
  background: #f7f7f7;
  border-radius: 12px;
  padding: 1.4em;
  color: #0d0d0d;
}


.stepperSubDiv h1 {
  font-weight: 500;
  text-align: center;
  color: #000201;
}

.stepperBtn {
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  background: #8e4dce;
  background: #8e4dce;
  color: #f7f7f7;
  border-radius: 12px;
  padding: 0.5em 1.4em;
  font-size: 1.2em;
  font-weight: 500;
  margin: 1.4em;
}

.locationcard {
  width: 160px;
  height: 200px;
  margin-top: 45px;
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 10px; 
  background-color: rgb(238, 244, 255);
  margin-left: 10px;
}

.locationcard:hover {
  box-shadow: 0 4px 8px #8e4dce;
  transform: scale(1.05);
}


.overflow-scroll {
  overflow-x: auto;
  white-space: nowrap;
}



