
.alignCenter{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.alignCentertwo{
text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-style {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px 30px;
  display: inline-block;
  width: 400px;
}

.levelbutton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.entitybutton{
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(229, 153, 153);
}

.addapprovalbutton{
 display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(229, 153, 153);
}


.submitbutton {
  background-color: #3498db; 
  color: #ffffff;
  margin-bottom: 20px;
  
}


.submitbutton.d-flex {
  justify-content: center;
  align-items: center;
}

.submitbutton:hover {
  background-color: #2980b9; 
}



